import {
  CheckmarkIcon,
  DotIcon as _DotIcon,
  Flex,
  Greyscale,
  PrimaryColor,
  ScreenSize,
  SecondaryColor,
  Tooltip as BaseToolTip,
} from 'glints-aries';
import { Colors, Divider as AriesDivider, Tag } from 'glints-aries/lib/@next';
import { Red } from 'glints-aries/lib/@next/utilities/colors';
import { space12 } from 'glints-aries/lib/@next/utilities/spacing';
import styled, { css } from 'styled-components';

import JobCard from 'src/components/Cards/JobCard';
import { ellipsis } from 'src/components/Ellipsis.sc';

import { CompanyLogo } from '../GlintsPicture';

const companyAvatarSize = '32px';
const setClosedJobsBorderColor = (hasClosed: boolean) =>
  hasClosed ? 'rgba(198, 198, 198, 0.5)' : Greyscale.lightgrey;

export const VerifiedScamFraudJobTagContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const VerifiedScamFraudJobTag = styled(Tag)`
  background-color: ${Red.B100};
  cursor: default;
  color: ${Red.B93};
`;

const roundedBorder = css`
  border: 1px solid ${Greyscale.lightgrey};
  border-radius: 4px;
`;

const softBorder = css`
  border-top: 2px solid ${Greyscale.softgrey};
  border-radius: 0;
`;

const rightBorder = css`
  border-right: 2px solid ${Greyscale.softgrey};
  border-bottom: 2px solid ${Greyscale.softgrey};
`;

const thinRightBorder = css`
  border: 1px solid;
  border-color: transparent ${Greyscale.softgrey} ${Greyscale.softgrey}
    transparent;
`;

const borders = {
  round: roundedBorder,
  soft: softBorder,
  right: rightBorder,
  thinRight: thinRightBorder,
};

export const AppliedTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #eef0f3;
  color: #2d2d2d;
  padding: 2.5px 12px;
  font-size: 12px;
  border-radius: 7px 0px 16px 0px;
  font-weight: 500;
  opacity: 100%;
`;

export const AppliedIcon = styled(CheckmarkIcon)`
  margin-right: 4px;
`;

export const CompactJobCard = styled.div<{
  border?: keyof typeof borders;
  active?: boolean;
  activatable?: boolean;
  isHotJob?: boolean;
  isApplied?: boolean;
  isFypPage?: boolean;
}>`
  ${({ border, isFypPage }) => border && !isFypPage && `border-radius: 8px;`}
  position: relative;
  box-shadow:
    0px 0px 5px 0px rgba(71, 71, 71, 0.1),
    0px 1px 2px 0px rgba(71, 71, 71, 0.2);
  padding: 16px 12px;
  display: block;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ active }) => active && `background-color: ${Colors.Neutral.B99};`}
  ${({ isApplied }) =>
    isApplied &&
    css`
      & > *:not(${AppliedTagContainer}) {
        opacity: 50%;
      }
    `}

  ${({ isHotJob }) =>
    isHotJob && `box-shadow: 2px 6px 15px rgba(245, 166, 35, 0.22)`}
  ${({ activatable }) => activatable && `cursor: pointer;`}

  @media (min-width: ${ScreenSize.tablet}px) {
    ${({ border, active }) =>
      active &&
      border === 'thinRight' &&
      `border: 1px solid ${Colors.Blue.S99};`}
  }
`;

export const CompactJobCardWrapper = styled(JobCard)`
  position: relative;
  min-width: 100%;
  border: none;
  border-radius: 8px;

  ${CompactJobCard} {
    ${({ isApplied }) =>
      isApplied &&
      css`
        & > *:not(${AppliedTagContainer}) {
          opacity: 50%;
        }
      `}
    & ${AppliedTagContainer} {
      opacity: 1;
    }
  }

  &:hover {
    box-shadow:
      0 8px 20px 0 rgba(71, 71, 71, 0.2),
      0 3px 6px -3px rgba(71, 71, 71, 0.08);
    border-color: ${Greyscale.grey};
    cursor: pointer;
    transition: all 0.1s;
    overflow: hidden;

    ${CompactJobCard} {
      ${({ isApplied }) =>
        isApplied &&
        css`
          & > *:not(${AppliedTagContainer}) {
            opacity: 50%;
          }
        `}
      & ${AppliedTagContainer} {
        opacity: 1;
      }
    }
  }

  &:after {
    content: ${({ hasClosed }) => (hasClosed ? 'none' : '')};

    ${({ hasClosed }) =>
      hasClosed &&
      css`
        border-color: ${setClosedJobsBorderColor(hasClosed)};

        ${OpportunityMeta} {
          border-top-color: ${setClosedJobsBorderColor(hasClosed)};
        }

        ${CompanyLink} .aries-tooltip {
          pointer-events: none;
        }
      `}
  }
`;

export const CompanyAvatar = styled(CompanyLogo)`
  width: ${companyAvatarSize};
  height: ${companyAvatarSize};
  object-fit: contain;
`;

export const CompanyAvatarWrapper = styled.div`
  width: ${companyAvatarSize};
  height: ${companyAvatarSize};
`;

export const BookmarkIconContainer = styled.div`
  position: relative;
  float: right;

  @media (min-width: ${ScreenSize.mobileS}px) {
    padding-top: 5px;
  }

  @media (max-width: ${ScreenSize.mobileL}px) {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const CompactJobCardInfo = styled.div`
  width: 100%;
  max-width: 70%;

  @media (min-width: ${ScreenSize.mobileS + 1}px) {
    max-width: none;
  }
`;

export const JobTitleSalaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

export const JobTitle = styled.h2`
  font-size: 16px;
  margin-right: ${space12};
  font-weight: 500;

  display: block;
  line-height: normal;
  letter-spacing: 0.25px;

  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
`;

export const JobCardNoStyleAnchor = styled.a`
  all: unset;
  &:hover {
    text-decoration: underline;
  }
`;

export const CompanyLink = styled.a<{ isSpwdOpportunity?: boolean }>`
  color: ${({ isSpwdOpportunity }) =>
    isSpwdOpportunity ? Greyscale.black : SecondaryColor.actionblue};
  font-size: 14px;

  &:hover {
    text-decoration: ${({ isSpwdOpportunity }) =>
      isSpwdOpportunity ? 'none' : 'underline'};
  }
  letter-spacing: 0.25px;
  ${ellipsis(1)}
`;

export const CompanyInformation = styled(Flex)`
  align-items: center;
  column-gap: 6px;
  margin-bottom: 4px;
  position: relative;
  margin-left: 2px;
  width: 100%;

  img,
  && svg {
    flex-shrink: unset;
    flex-basis: 18px;
    margin-top: 0;
    width: 18px;
    height: 18px;
  }
`;

export const LoginLink = styled.a`
  color: ${SecondaryColor.actionblue};
  &:hover {
    text-decoration: underline;
  }
  position: relative;
`;

export const CompanyLinkContainer = styled.span`
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: start;
  margin: 8px 0;
`;

export const CompanyDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  max-width: 80%;
`;

export const OpportunityInfoContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10.5px;

  > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p:not(:empty):not(:first-child) {
    margin-top: 5px;
  }
`;

export const OpportunityInfo = styled.div`
  &:empty {
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin-bottom: 6px;
  flex: 1 1 0;
  align-items: center;
  column-gap: 6px;

  svg {
    width: 20px;
    height: 20px;
    margin-top: 2px;
    color: ${Greyscale.grey};
  }
`;

export const OpportunityUpper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const OpportunityLower = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

export const OpportunityFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 0;
`;

export const OpportunityMeta = styled.div`
  display: flex;
  align-items: center;
  color: #646464;
  font-size: 12px;
  gap: 8px;

  svg {
    margin-right: 5px;
  }
  span {
    vertical-align: middle;
  }
`;

export const ClosedJobMessage = styled.p`
  display: inline;
  margin: 0;
  color: ${PrimaryColor.glintsred};
`;

export const UpdatedAtMessage = styled.span`
  color: #646464;
  letter-spacing: 1.5px;
  &[data-recent='true'] {
    color: ${SecondaryColor.darkgreen};
    font-weight: 500;
  }
`;

export const NotDisclosedMessage = styled.span`
  color: ${Greyscale.grey};
  font-weight: 600;
  white-space: nowrap;
`;

export const CardBottomFlexContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -10px;
`;

export const UpdatedTimeContainer = styled.div`
  height: 100%;
  margin-top: 10px;
`;

export const IsActivelyHiringContainer = styled.span`
  margin-top: 10px;
  background-color: ${SecondaryColor.darkgreen};
  border: 1px solid ${SecondaryColor.darkgreen};
  border-radius: 100px;
  padding: 4px 10px;
  color: ${Greyscale.white};
  margin-right: 8px;
`;

export const DotIcon = styled(_DotIcon)`
  margin-right: 8px !important;
`;

export const SalaryWrapper = styled.span`
  white-space: nowrap;
  color: ${SecondaryColor.actionblue};
  font-weight: 600;
`;

export const SalaryEstimateContainer = styled.div`
  display: inline-block;
  position: relative;
  align-items: center;
  svg {
    height: 15px;
    width: 15px;
  }
`;

export const SalaryEstimate = styled.span`
  margin-right: 4px;
`;

export const ToolTip = styled(BaseToolTip)`
  display: block;
  div {
    width: 242px;
  }
`;

export const Divider = styled(AriesDivider)``;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 8px 0;
`;
