import { useSelector } from 'react-redux';

import { CountryCodes } from 'src/common/enums';
import { State } from 'src/global/store';

/**
 * this function return closure that check country code against feature flag with country codes
 *
 * @param selectorFn pass a function that receive state and returns list of enabled country codes for this feature flag
 */
export const useFeatureFlagEnabledByCountryCode = (
  selectorFn: (state: State) => CountryCodes[]
) => {
  const countryCodes = useSelector(selectorFn);
  return (countryCode: CountryCodes): boolean => {
    return countryCodes.includes(countryCode);
  };
};
