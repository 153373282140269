import React from 'react';
import { ClockIcon } from 'glints-aries';
import PropTypes from 'prop-types';

import { JobcardDescriptionWrapper } from './JobCard.sc';

const JobCardDescription = ({ description, time, ...defaultProps }) => (
  <JobcardDescriptionWrapper {...defaultProps}>
    <p>{description}</p>
    <span>
      <ClockIcon color="#646464" />
      {time}
    </span>
  </JobcardDescriptionWrapper>
);

JobCardDescription.propTypes = {
  description: PropTypes.string.isRequired,
  time: PropTypes.node.isRequired,
  isLinkAble: PropTypes.bool, // prop is used in JobCard
};

export default JobCardDescription;
