import React from 'react';
import { find, isEmpty } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

import * as Styles from 'src/components/Cards/CompactOpportunityCard.sc';
import { JobInterface, Salary } from 'src/modules/Opportunity/interface';
import { formatCurrency } from 'src/modules/OpportunityV2/helpers';

interface Props {
  opportunity: JobInterface & {
    links?: { jobSalaries: Salary[] };
    JobSalaries?: Salary[];
  };
}

export const CompactJobOpportunityCardSalary: React.FC<
  React.PropsWithChildren<Props>
> = ({ opportunity }) => {
  const intl = useIntl();

  const jobSalaries = find(
    [
      opportunity.salaries,
      opportunity?.links?.jobSalaries,
      opportunity?.JobSalaries,
    ],
    (salary) => !isEmpty(salary)
  );
  const basicSalary = jobSalaries
    ? jobSalaries.find((jobSalary) => jobSalary.salaryType === 'BASIC')
    : null;

  if (!isEmpty(basicSalary) && opportunity?.shouldShowSalary) {
    const { minAmount, maxAmount, CurrencyCode, salaryMode } = basicSalary;

    return (
      <Styles.SalaryWrapper
        key={`${CurrencyCode}-${minAmount}-${maxAmount}-${salaryMode}`}
      >
        {formatCurrency(intl, CurrencyCode, minAmount, maxAmount)}
      </Styles.SalaryWrapper>
    );
  } else {
    return (
      <Styles.NotDisclosedMessage>
        <FormattedMessage
          id="job.card.salary.estimate.not.disclosed"
          defaultMessage="Not Disclosed"
        />
      </Styles.NotDisclosedMessage>
    );
  }
};
