import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { JobCardFooterWrapper } from './JobCard.sc';

const JobCardFooter = props => {
  const { children, className, ...defaultProps } = props;

  return (
    <JobCardFooterWrapper
      className={classNames('jobcard-footer', className)}
      role="presentation"
      aria-label="Job Card Footer"
      tabIndex={0}
      {...defaultProps}
    >
      {children}
    </JobCardFooterWrapper>
  );
};

JobCardFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isLinkAble: PropTypes.bool, // prop is used in JobCard
};

export default JobCardFooter;
