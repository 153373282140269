import React from 'react';
import { DotIcon } from 'glints-aries';
import PropTypes from 'prop-types';

import { IconHolder, JobcardDetailWrapper } from './JobCard.sc';

const JobCardDetail = ({ details, id, ...defaultProps }) => (
  <JobcardDetailWrapper {...defaultProps}>
    <ul>
      {details
        .filter(detail => detail)
        .map((detail, index) => (
          <div key={`${detail.key}-${id}-${index}`}>
            <IconHolder>
              <DotIcon color="#c6c6c6" />
            </IconHolder>
            <li>{detail}</li>
          </div>
        ))}
    </ul>
  </JobcardDetailWrapper>
);

JobCardDetail.propTypes = {
  details: PropTypes.array.isRequired,
  id: PropTypes.node.isRequired,
  isLinkAble: PropTypes.bool, // prop is used in JobCard
};

export default JobCardDetail;
