import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { JobcardBodyWrapper } from './JobCard.sc';

const JobCardBody = props => {
  const { children, className, ...restProps } = props;

  return (
    <JobcardBodyWrapper
      className={classNames('jobcard-body', className)}
      role="presentation"
      aria-label="Job Card Body"
      tabIndex={0}
      {...restProps}
    >
      {children}
    </JobcardBodyWrapper>
  );
};

JobCardBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isLinkAble: PropTypes.bool, // prop is used in JobCard
};

export default JobCardBody;
