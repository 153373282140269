import { useEffect, useState } from 'react';
import { ScreenSize } from 'glints-aries';

/**
 * This helps resolve the hydration issue:
 * 1. SSR: no target attribute (undefined)
 * 2. Client initial render: still no target (matches SSR)
 * 3. After mount: useEffect sets the appropriate target
 */

export const useResponsiveOpenTargetAttribute = (
  mobileTarget = '_self',
  desktopTarget = '_blank'
) => {
  const [target, setTarget] = useState<string | undefined>(undefined);

  useEffect(() => {
    const checkIfMobile = window.matchMedia(
      `(max-width: ${ScreenSize.desktopS}px)`
    ).matches;
    setTarget(checkIfMobile ? mobileTarget : desktopTarget);
  }, [desktopTarget, mobileTarget]);

  return target;
};
