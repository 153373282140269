import { ScreenSize } from 'glints-aries';

export const Device = {
  mobileS: `(max-width: ${ScreenSize.mobileS}px)`,
  mobileM: `(max-width: ${ScreenSize.mobileM}px)`,
  mobileL: `(max-width: ${ScreenSize.mobileL}px)`,
  tablet: `(max-width: ${ScreenSize.tablet}px)`,
  desktopS: `(max-width: ${ScreenSize.desktopS}px)`,
  desktopM: `(max-width: ${ScreenSize.desktopM}px)`,
  desktopL: `(max-width: ${ScreenSize.desktopL}px)`,
};
