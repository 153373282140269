import React, { Component } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { CustomLink, JobcardContainer, JobCardWrapper } from './JobCard.sc';
import JobCardBody from './JobCardBody';
import JobCardDescription from './JobCardDescription';
import JobCardDetail from './JobCardDetail';
import JobCardFooter from './JobCardFooter';
import JobCardHeader from './JobCardHeader';

class JobCard extends Component {
  static Header = JobCardHeader;
  static Body = JobCardBody;
  static Detail = JobCardDetail;
  static Description = JobCardDescription;
  static Footer = JobCardFooter;

  renderLinkChild = () => {
    const { children, targetUrl, ...restProps } = this.props;
    const linkChild = React.Children.map(children, child => {
      if (targetUrl && child.props.isLinkAble) {
        return React.cloneElement(child, { ...restProps });
      }
      return null;
    });
    return linkChild;
  };

  renderNonLinkChild = () => {
    const { children } = this.props;
    const nonLinkChild = React.Children.map(children, child => {
      if (!child.props.isLinkAble) {
        return child;
      }
      return null;
    });
    return nonLinkChild;
  };

  render() {
    const { className, targetUrl, targetAs, showHoverAnimation, ...restProps } =
      this.props;
    return (
      <JobcardContainer
        className={className}
        showHoverAnimation={showHoverAnimation}
      >
        <JobCardWrapper
          role="presentation"
          aria-label="Job Card"
          tabIndex={0}
          {...restProps}
        >
          {targetUrl ? (
            <React.Fragment>
              <Link
                href={targetUrl}
                as={targetAs}
                passHref={true}
                prefetch={false}
              >
                <CustomLink>{this.renderLinkChild()}</CustomLink>
              </Link>
              {this.renderNonLinkChild()}
            </React.Fragment>
          ) : (
            this.renderNonLinkChild()
          )}
        </JobCardWrapper>
      </JobcardContainer>
    );
  }
}

JobCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  targetUrl: PropTypes.string,
  targetAs: PropTypes.string,
  showHoverAnimation: PropTypes.bool,
};

JobCard.defaultProps = {
  showHoverAnimation: true,
};

export default JobCard;
