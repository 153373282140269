import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  Image,
  JobcardHeaderContent,
  JobcardHeaderImage,
  JobcardHeaderWrapper,
  LabelTag,
  SubtitleTitle,
  Title,
} from './JobCard.sc';

const JobCardHeader = props => {
  const {
    title,
    tag,
    imgUrl,
    subtitle,
    onClickSubtitle,
    className,
    jobTitleClass,
    companyNameClass,
    ...defaultProps
  } = props;

  return (
    <JobcardHeaderWrapper
      className={classNames('jobcard-header', className)}
      role="presentation"
      aria-label="Job Card Header"
      tabIndex={0}
      {...defaultProps}
    >
      <JobcardHeaderImage aria-hidden="true">
        {typeof imgUrl === 'string' ? <Image src={imgUrl} alt="" /> : imgUrl}
      </JobcardHeaderImage>
      <JobcardHeaderContent>
        {tag && <LabelTag>{tag}</LabelTag>}
        <Title className={jobTitleClass}>{`${title.slice(0, 50)}`}</Title>
        <SubtitleTitle
          onClick={onClickSubtitle}
          className={companyNameClass}
          role="link"
          tabIndex={0}
        >
          {subtitle}
        </SubtitleTitle>
      </JobcardHeaderContent>
    </JobcardHeaderWrapper>
  );
};

JobCardHeader.propTypes = {
  className: PropTypes.string,
  companyNameClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  jobTitleClass: PropTypes.string.isRequired,
  onClickSubtitle: PropTypes.func.isRequired,
  isLinkAble: PropTypes.bool, // prop is used in JobCard
};

JobCardHeader.defaultProps = {
  jobTitleClass: '',
  companyNameClass: '',
  onClickSubtitle: () => {},
};

export default JobCardHeader;
